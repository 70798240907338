import React, { useCallback, useEffect, useRef, useState } from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import { ClientsService } from "../api"
import Fieldset from "../components/Fieldset"
import GeoCoder from "../components/GeoCoder"
import Button from "../components/Button"
import Input from "../components/Input"
import NotificationMethodWarning from "../components/NotificationMethodWarning"
import NotificationNotice from "../components/NotificationNotice"
import PaymentCard from "../components/PaymentCard"
import PhoneNumberField from "../components/PhoneNumberField"
import TimezoneField from "../components/TimezoneField"
import { usePaymentMethod } from "../hooks"
import { Address } from "../util/Address"

import type { ClientNotificationPreferences, ClientProfile } from "../api"

const MASK_SMS = false

function handleFetchError(resp: Response) {
  if (!resp.ok) throw new Error(`Status ${resp.status} for ${resp.url}`)
}

async function load(refreshCounter: number, token: string) {
  const url = new URL(
    "/api/clients/profile/",
    process.env.REACT_APP_BASE_API_URL
  )
  const oldProfileP = fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(async resp => {
    handleFetchError(resp)
    return await resp.json()
  })

  const profileP = ClientsService.retrieveClientProfile()
  const timezoneP = ClientsService.retrieveClientTimezoneSetting()
  const notificationPrefsP =
    ClientsService.retrieveClientNotificationPreferences()

  const oldProfile = await oldProfileP
  const profile = await profileP
  const timezone = await timezoneP
  const notificationPrefs = await notificationPrefsP

  return { oldProfile, profile, timezone, notificationPrefs }
}

const Profile: React.FC = () => {
  const [refreshCounter, setRefreshCounter] = useState(0)
  const { getAccessTokenSilently } = useAuth0()
  const [saving, setSaving] = useState(false)
  const [hasChangedPassword, setHasChangedPassword] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>(false)

  const [phoneData, setPhoneData] = useState({
    number: "",
    valid: true,
    changed: false,
  })

  type NotificationKey = keyof ClientNotificationPreferences

  const [notificationPrefs, setNotificationPrefs] =
    useState<ClientNotificationPreferences | null>(null)

  const [profileData, setProfileData] = useState<any>()
  const [profileAddress, setProfileAddress] = useState<Address>()
  const [profile2Data, setProfile2Data] = useState<ClientProfile>()
  const [timezoneName, setTimezoneName] = useState<string>()

  function caughtError(e: any) {
    setProfileData(undefined)
    setProfileAddress(undefined)
    setProfile2Data(undefined)
    setError(e)
    console.error("Caught error", e)
  }

  const loadCallback = useCallback(async () => {
    setIsLoading(true)
    const token = await getAccessTokenSilently()
    try {
      const { oldProfile, profile, timezone, notificationPrefs } = await load(
        refreshCounter,
        token
      )
      setProfileData(oldProfile)
      setProfileAddress(
        oldProfile.street_address_parts
          ? new Address(oldProfile.street_address_parts)
          : undefined
      )
      setPhoneData({
        number: profile.phone_number,
        valid: true,
        changed: false,
      })
      setProfile2Data(profile)
      setTimezoneName(timezone.timezone_name)
      setNotificationPrefs(notificationPrefs)
    } catch (e) {
      caughtError(e)
    } finally {
      setIsLoading(false)
    }
  }, [getAccessTokenSilently, refreshCounter])

  useEffect(() => {
    loadCallback()
  }, [loadCallback])

  let stylistUuid =
    process.env.REACT_APP_DEFAULT_STYLIST || "stylist not set in build env"
  if (!isLoading && profile2Data != null) {
    const favs = profile2Data.favorite_stylists
    if (favs != null && favs.length > 0) stylistUuid = favs[0].uuid
  }

  const { paymentMethodData, paymentMethodIsLoading, paymentMethodError } =
    usePaymentMethod({
      refreshCounter,
      stylistUuid,
    })

  type payloadType = {
    name?: string
    email?: string
    street_address?: string
    street_address_unit?: string
  }
  const payload: payloadType = {}

  /* The backend requires that street_address always be passed for an address update.
     This helps it detect when the unit part is being cleared (as opposed to not
     included because the address isn't changing). */
  let addressChanged = false,
    unitChanged = false

  function setAddress(address: string) {
    addressChanged = true
    payload.street_address = address
    if (!unitChanged) payload.street_address_unit = profileAddress?.unit
  }

  function setUnit(unit: string) {
    unitChanged = true
    if (!addressChanged) payload.street_address = profileAddress?.fullLineNoUnit
    payload.street_address_unit = unit || undefined
  }

  async function saveProfile() {
    setSaving(true)
    const token = await getAccessTokenSilently()
    const url = new URL(
      "/api/clients/profile/",
      process.env.REACT_APP_BASE_API_URL
    )
    try {
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
      handleFetchError(resp)
      await ClientsService.updateClientProfile({
        requestBody: {
          phone_number: phoneData.number,
        },
      })
      setRefreshCounter(refreshCounter + 1)
    } catch (e) {
      caughtError(e)
    } finally {
      setSaving(false)
    }
  }

  async function saveNotificationPreferences() {
    if (notificationPrefs == null) return
    setSaving(true)
    try {
      await ClientsService.partialUpdateClientNotificationPreferences({
        requestBody: notificationPrefs,
      })
      setRefreshCounter(refreshCounter + 1)
    } catch (e) {
      caughtError(e)
    } finally {
      setSaving(false)
    }
  }

  async function changePassword() {
    setSaving(true)
    const token = await getAccessTokenSilently()
    const url = new URL(
      "/api/clients/change_password/",
      process.env.REACT_APP_BASE_API_URL
    )
    try {
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email: profileData.email }),
      })
      handleFetchError(resp)
      setHasChangedPassword(true)
    } catch (e) {
      caughtError(e)
    } finally {
      setSaving(false)
    }
  }

  type OptionProps = {
    children: any
    prefKey: NotificationKey
  }

  const Option: React.FC<OptionProps> = props => {
    function onChange(val: boolean) {
      const newPrefs = Object.assign({}, notificationPrefs)
      newPrefs[props.prefKey] = val
      setNotificationPrefs(newPrefs)
    }

    return (
      <li>
        <label>
          <Input
            className="mr-2"
            isLoading={isLoading}
            type="checkbox"
            checked={(notificationPrefs || {})[props.prefKey]}
            onChange={event => onChange(event.target.checked)}
          />
          {props.children}
        </label>
      </li>
    )
  }

  const indeterminateRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const el = indeterminateRef.current
    if (MASK_SMS && el != null) el.indeterminate = true
  })

  const notifications_warning =
    notificationPrefs != null &&
    !notificationPrefs.wants_email &&
    (MASK_SMS || !notificationPrefs.wants_sms)

  return (
    <div className="container my-8 mx-auto max-w-3xl px-4 pb-12 text-left sm:px-6 lg:px-8">
      <h1 className="mb-6 text-3xl font-bold tracking-tight text-indigo-600">
        Your profile
      </h1>

      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <h2 className="mb-6 text-xl font-bold tracking-tight text-gray-700">
          Profile details
        </h2>
        {error ? (
          <div>ERROR!</div>
        ) : (
          <>
            <div className="mb-6">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <Input
                  isLoading={isLoading}
                  disabled={saving}
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={profileData?.name}
                  onChange={e => (payload.name = e.target.value)}
                  placeholder="Saul Goodman"
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <Input
                  isLoading={isLoading}
                  disabled={saving}
                  type="email"
                  name="email"
                  id="email"
                  defaultValue={profileData?.email}
                  onChange={e => (payload.email = e.target.value)}
                  placeholder="saul@test.com"
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone
              </label>
              <div className="mt-1">
                <PhoneNumberField
                  isLoading={isLoading}
                  disabled={saving}
                  value={phoneData.number}
                  onChange={(number, valid) =>
                    setPhoneData({ number, valid, changed: true })
                  }
                />
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Address
                {isLoading ? (
                  <Input isLoading={isLoading} />
                ) : (
                  <GeoCoder
                    defaultValue={profileAddress?.fullLineNoUnit}
                    disabled={saving}
                    handleGeocoderValue={v => setAddress(v.place_name)}
                  />
                )}
              </label>
            </div>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Unit (optional)
                <Input
                  isLoading={isLoading}
                  disabled={saving}
                  name="unit"
                  id="unit"
                  defaultValue={profileAddress?.unit}
                  onChange={e => setUnit(e.target.value)}
                  className="mt-1 block h-10 w-full rounded-md border border-gray-200 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Apt 2"
                />
              </label>
            </div>
            <div className="mb-10">
              <label className="block text-sm font-medium text-gray-700">
                Timezone
                <TimezoneField isLoading={isLoading} value={timezoneName} />
              </label>
              <p className="mt-2 text-xs text-gray-500">
                To change your timezone, change the timezone on your device and
                reload this page.
              </p>
            </div>
            <Button
              disabled={saving || !phoneData.valid}
              isLoading={isLoading}
              onClick={saveProfile}
              scheme="indigo"
            >
              Update profile
            </Button>
          </>
        )}
      </div>

      <div className="mt-6 bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <h2 className="mb-6 text-xl font-bold tracking-tight text-gray-700">
          Notification preferences
        </h2>
        {error && <div>ERROR!</div>}
        <Fieldset>
          <legend>Notify me by</legend>
          <ul>
            <Option prefKey="wants_email">E-mail</Option>
            {MASK_SMS ? (
              <li>
                <label>
                  <input
                    checked
                    className="mr-2"
                    disabled
                    ref={indeterminateRef}
                    type="checkbox"
                  />
                  SMS <em>(coming soon)</em>
                </label>
              </li>
            ) : (
              <Option prefKey="wants_sms">SMS</Option>
            )}
          </ul>
          <NotificationMethodWarning showWarning={notifications_warning} />
          <NotificationNotice />
        </Fieldset>
        <Fieldset>
          <legend>Notify me about</legend>
          <ul>
            <Option prefKey="notify_new_messages">
              New messages in the app
            </Option>
            <Option prefKey="notify_appt_tentative">
              I ask for an appointment
            </Option>
            <Option prefKey="notify_appt_accepted">
              I accept an appointment
            </Option>
            <Option prefKey="notify_appt_confirmed">
              My service provider confirms an appointment
            </Option>
            <Option prefKey="notify_appt_declined">
              My service provider declines an appointment
            </Option>
            <Option prefKey="notify_appt_canceled">
              My service provider cancels an appointment
            </Option>
            <Option prefKey="notify_appt_completed">
              We finish an appointment
            </Option>
            <Option prefKey="notify_appt_expired">
              My appointment request expires
            </Option>
          </ul>
        </Fieldset>
        <Button
          disabled={saving}
          isLoading={isLoading}
          onClick={saveNotificationPreferences}
        >
          Update preferences
        </Button>
      </div>

      <div className="mt-6 bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <h2 className="mb-6 text-xl font-bold tracking-tight text-gray-700">
          Password
        </h2>
        {error ? (
          <div>ERROR!</div>
        ) : (
          <>
            <div className="mb-6">
              <p className="mt-3 text-gray-500">
                {hasChangedPassword ? (
                  <>
                    An e-mail has been sent with instructions to change your
                    password.
                  </>
                ) : (
                  <>
                    To change your password, we will send you an e-mail with
                    instructions.
                  </>
                )}
              </p>
            </div>
            <Button
              disabled={saving || hasChangedPassword}
              isLoading={isLoading}
              onClick={changePassword}
            >
              Change password
            </Button>
          </>
        )}
      </div>

      <PaymentCard
        error={paymentMethodError}
        isLoading={isLoading || paymentMethodIsLoading}
        methodData={paymentMethodData}
        stylistUuid={stylistUuid}
      />
    </div>
  )
}

export default withAuthenticationRequired(Profile)
