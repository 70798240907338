import React from "react"
import { useHits } from "react-instantsearch-hooks-web"

type Service = {
  category_name: string
  cost: number
  duration: number
  id: number
  service: number
  service_name: string
}

type Result = {
  name: string
  picture: string
  services?: Service[]
  // add more if needed
}

const SearchResults = () => {
  const { hits } = useHits()

  return (
    <>
      {
        // @ts-ignore
        hits.map((result: Result) => {
          return (
            <div
              key={result.name}
              className="relative flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-14 w-14 rounded-full"
                  src={result.picture}
                  alt=""
                />
              </div>
              <div className="min-w-0 flex-1 pl-3">
                <h2 className="text-lg font-medium text-gray-900">
                  {result.name}
                </h2>
                <p className="truncate text-gray-500">some description</p>
                <div className="mt-3 flex justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="#"
                      className="flex w-full items-center justify-center rounded-md border border-gray-200 bg-transparent px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200"
                    >
                      Book appointment
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a
                      href="#"
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-transparent px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-gray-100"
                    >
                      View profile
                    </a>
                  </div>
                </div>
              </div>
              <div className="min-w-0 flex-1 self-start text-right">
                {result.services?.map((service: Service) => {
                  return (
                    <dd className="mr-2 mb-2 inline-block">
                      <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                        {service.category_name}
                      </span>
                    </dd>
                  )
                })}
              </div>
            </div>
          )
        })
      }
    </>
  )
}

export default SearchResults
