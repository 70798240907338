import { useCallback, useEffect, useRef, useState } from "react"

import { ClientsService, ClientOnboardingStatus } from "../api"
import Button from "../components/Button"
import Input from "../components/Input"
import NotificationMethodWarning from "../components/NotificationMethodWarning"
import NotificationNotice from "../components/NotificationNotice"
import Spinner from "../components/Spinner"
import ToSLink from "../components/ToSLink"

const MASK_SMS = false

type TermsAcceptanceProps = {
  onboardingStatus: ClientOnboardingStatus
  setOnboardingStatus: (s: ClientOnboardingStatus) => void
}

const TermsAcceptance: React.FC<TermsAcceptanceProps> = ({
  onboardingStatus,
  setOnboardingStatus,
}) => {
  const [interacted, setInteracted] = useState(false)
  const [email, setEmail] = useState(false)
  const [sms, setSms] = useState(false)
  const [agreed, setAgreed] = useState(false)
  const [busy, setBusy] = useState(false)

  const indeterminateRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const el = indeterminateRef.current
    if (MASK_SMS && el != null) el.indeterminate = true
  })

  const showNotificationMethods = !onboardingStatus.notification_methods_set
  const showAcceptance = !onboardingStatus.has_accepted_tos

  const toggleEmail = useCallback(() => {
    setInteracted(true)
    setEmail(e => !e)
  }, [])

  const toggleSms = useCallback(() => {
    setInteracted(true)
    setSms(s => !s)
  }, [])

  const toggleAgreed = useCallback(() => {
    setInteracted(true)
    setAgreed(a => !a)
  }, [])

  const canContinue = (!showAcceptance || agreed) && !busy

  const next = useCallback(async () => {
    if (!canContinue) return
    setBusy(true)
    const promises = []
    if (showNotificationMethods) {
      promises.push(
        ClientsService.partialUpdateClientNotificationPreferences({
          requestBody: {
            wants_email: email,
            wants_sms: sms,
          },
        })
      )
    }
    if (showAcceptance) {
      promises.push(ClientsService.updateClientTermsAcceptance({}))
    }
    await Promise.all(promises)
    setOnboardingStatus(await ClientsService.retrieveClientOnboardingStatus())
    setInteracted(false)
    setEmail(false)
    setSms(false)
    setAgreed(false)
    setBusy(false)
  }, [
    canContinue,
    email,
    setOnboardingStatus,
    showAcceptance,
    showNotificationMethods,
    sms,
  ])

  return (
    <div className="mx-auto mb-32 mt-16 max-w-[525px] rounded-lg bg-white px-8 pb-10 pt-9 shadow-lg">
      <p className="text-sm text-gray-500">
        Welcome to Clexi! Before logging in, please select your notification
        preferences and accept our terms of service.
      </p>
      {showNotificationMethods && (
        <div className="mt-11">
          <h2 className="text-xl font-semibold text-indigo-600">
            Notification settings
          </h2>
          <p className="mt-3">
            We will only notify you about <b>appointment updates</b> and{" "}
            <b>messages from your service provider</b>.
          </p>
          <div className="mt-6 text-[#111928]">
            <label>
              <Input checked={email} onChange={toggleEmail} type="checkbox" />
              Email notifications
            </label>
          </div>
          <div className="mt-4 text-[#111928]">
            {MASK_SMS ? (
              <label>
                <input
                  checked
                  className="mr-2 box-border h-5 w-5 rounded border border-[#dfe4ea] text-[#3758f9]"
                  disabled
                  ref={indeterminateRef}
                  type="checkbox"
                />
                SMS <em>(coming soon)</em>
              </label>
            ) : (
              <label>
                <Input checked={sms} onChange={toggleSms} type="checkbox" />
                SMS notifications
              </label>
            )}
          </div>
          <NotificationNotice suppressToS={showAcceptance} />
        </div>
      )}
      {showAcceptance && (
        <div className="mt-11">
          <h2 className="text-xl font-semibold text-indigo-600">
            Terms of service
          </h2>
          <p className="mt-3">
            You must accept the Terms of Service to use Clexi.
          </p>
          <div className="mt-6 text-[#111928]">
            <label>
              <Input checked={agreed} onChange={toggleAgreed} type="checkbox" />{" "}
              I have read and agree to Clexi’s <ToSLink />.
            </label>
          </div>
        </div>
      )}
      <div className="mt-14">
        <NotificationMethodWarning
          showWarning={
            showNotificationMethods &&
            (interacted || !showAcceptance) &&
            !email &&
            !sms
          }
        />
        <div className="mt-6">
          <Button disabled={!canContinue} onClick={next}>
            {busy ? <Spinner /> : "Accept and Continue"}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TermsAcceptance
