import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import homeImage from "../assets/3.jpeg"

const Home = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  return (
    <main className="lg:relative">
      <div className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl md:text-4xl lg:text-4xl xl:text-5xl">
            <span className="block xl:inline">
              Professional services{" "}
            </span>{" "}
            <span className="block text-indigo-600 xl:inline">
              in the comfort of your own home
            </span>
          </h1>
          <p className="mx-auto mt-4 max-w-md text-lg text-gray-500 sm:text-xl md:mt-6 md:max-w-3xl">
            Our talented service providers are skilled professionals and experts in
            their fields. Whether you’re looking to refresh your style with a
            haircut, book a relaxing massage, or find help with home
            organization, our providers are here to make it happen.
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              {!isAuthenticated ? (
                <button
                  onClick={() => loginWithRedirect()}
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
                >
                  Sign in or create an account!
                </button>
              ) : (
                <a
                  href="/bookings"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
                >
                  Your bookings
                </a>
              )}
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a
                target="_blank"
                href="https://www.concihairge.com/faq/"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50 md:py-4 md:px-10 md:text-lg"
              >
                Find out more
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={homeImage}
          alt=""
        />
      </div>
    </main>
  )
}

export default Home
