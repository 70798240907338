import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { withAuthenticationRequired } from "@auth0/auth0-react"

import { activateStylist, ClientsService } from "../../api"
import bookingsImage from "../../assets/1.jpeg"
import StylistCard from "../../components/StylistCard"
import BookingsCard from "../../components/BookingsCard"
import { sendGAEvent } from "../../util"

import type { ActiveStylist } from "../../api"

async function load() {
  const profile2 = await ClientsService.retrieveClientProfile()

  let stylistUuid = process.env.REACT_APP_DEFAULT_STYLIST
  if (stylistUuid == null) {
    throw new Error("REACT_APP_DEFAULT_STYLIST isn't set")
  }

  const favs = profile2.favorite_stylists
  if (favs != null && favs.length > 0) stylistUuid = favs[0].uuid

  return await ClientsService.retrieveStylist({ stylistUuid })
}

const Bookings = () => {
  const [stylist, setStylist] = useState<ActiveStylist>()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<any>()

  const loadHook = useCallback(async () => {
    try {
      setStylist(activateStylist(await load()))
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    loadHook()
  }, [loadHook])

  return (
    <div className="min-h-full sm:pb-52">
      <div
        className="bg-indigo-600 pb-52"
        style={{
          backgroundPosition: "center center",
          backgroundSize: "100%",
          backgroundAttachment: "fixed",
          backgroundImage: `url(${bookingsImage})`,
        }}
      ></div>

      <main className="-mt-32">
        <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            <StylistCard
              stylistData={stylist}
              loading={isLoading}
              error={error}
            >
              {stylist != null && (
                <div className="border-gray-200 px-6 pb-6">
                  <div className="flex items-stretch">
                    <Link
                      to={`/booking-detail/${stylist.uuid}`}
                      type="button"
                      onClick={() =>
                        sendGAEvent("appointments", "request new appointment")
                      }
                      className="block grid w-1/2 items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-center font-medium leading-4 text-indigo-600 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Request new appointment
                    </Link>
                    <Link
                      to={`/messages/${stylist.uuid}`}
                      type="button"
                      onClick={() => sendGAEvent("contact", "contact stylist")}
                      className="ml-4 block grid w-1/2 items-center rounded-md border border-transparent bg-indigo-500 px-4 py-3 text-center font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Contact
                    </Link>
                  </div>
                </div>
              )}
            </StylistCard>
            <BookingsCard />
          </div>
        </div>
      </main>
    </div>
  )
}

export default withAuthenticationRequired(Bookings)
