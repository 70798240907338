import { DateTime, Duration } from "luxon"
import React from "react"
import { Link } from "react-router-dom"
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid"

import { ClientsService, Coordinates } from "../api"
import { useApi, useGet } from "../hooks"
import * as format from "../util/format"

import LocationLink from "./LocationLink"

type Booking = {
  id: number
  name: string
  description?: string
  services: {
    name: string
    cost: string
    duration: number
  }[]
  stylist: {
    uuid: string
    picture?: string
    id?: number
    userId?: number
    fixed_location_address: string | null
    fixed_location_coordinates: Coordinates | null
  }
  state?: string
  cost: string
  duration: Duration
  paid_on?: DateTime
  start?: DateTime
}

type SectionProps = {
  children: any
  title: string
}

const Section: React.FC<SectionProps> = ({ children, title }) => {
  return (
    <section aria-labelledby="section-1-title">
      <h2 className="sr-only" id="section-1-title">
        {title}
      </h2>
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="py-6 pb-0">
          <h3 className="mb-6 px-6 text-xl font-semibold leading-6 text-gray-800">
            {title}
          </h3>
          {children}
        </div>
      </div>
    </section>
  )
}

type CardProps = {
  children: any
  error?: boolean
  loading?: boolean
  title: string
}

const Card: React.FC<CardProps> = ({ children, error, loading, title }) => {
  if (!loading && !error && !children) return <></>

  return (
    <Section title={title}>
      {!loading && !error && (
        <ul className="divide-y divide-gray-200">{children}</ul>
      )}
      {!loading && error && <div>ERROR!</div>}
      {loading && (
        <div className="flex animate-pulse space-x-4 p-6 pt-0">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-4 rounded bg-gray-200"></div>
            <div className="space-y-6">
              <div className="col-span-1 h-4 w-64 rounded bg-gray-200"></div>
              <div className="col-span-1 h-4 w-72 rounded bg-gray-200"></div>
              <div className="h-4 w-56 rounded bg-gray-200"></div>
              <div className="h-8 w-24 rounded bg-gray-200"></div>
            </div>
          </div>
        </div>
      )}
    </Section>
  )
}

type DetailProps = {
  booking: Booking
  children: any
}

const Detail: React.FC<DetailProps> = ({ booking, children }) => {
  return (
    <li className="border-t border-dashed">
      <div className="block py-8 px-6 pb-8 hover:bg-gray-50 sm:flex">
        <div className="mb-4 flex-shrink-0 sm:mb-0">
          <img
            className="h-162 w-16 rounded-full"
            src={booking.stylist.picture}
            alt=""
          />
        </div>
        <div className="grow sm:pl-6">
          <p className="text-l truncate font-medium text-indigo-600">
            {booking.name}
          </p>
          <div className="block justify-between align-baseline sm:flex">
            <h2 className="mt-1 text-xl">
              {booking.start ? (
                <time dateTime={booking.start.toISO()}>
                  {booking.start.toFormat("EEE, ff ZZZZ")}
                </time>
              ) : (
                <>
                  <i>Click </i>Review<i> to pick a date</i>
                </>
              )}
            </h2>
            <p className="mt-2 self-center sm:mt-0">
              <b className="text-gray-800">
                ${booking.cost}
                {booking.paid_on && (
                  <> (paid {booking.paid_on.toFormat("ff")})</>
                )}
              </b>
              <span className="mx-3">/</span>
              <b className="text-gray-500">
                {booking.duration.shiftTo("minutes").toHuman()}
              </b>
            </p>
          </div>

          <div className="mr-2 mt-4 mb-4" style={{ maxWidth: 440 }}>
            {booking.services.map((service, i) => (
              <p
                key={`${i}:${service.name}:${service.cost}:${service.duration}`}
                className="mb-3 mr-3 inline-flex rounded-full bg-green-100 px-3 text-xs font-semibold leading-7 text-green-800"
              >
                {service.name} ${service.cost}
              </p>
            ))}
          </div>

          {booking.description && (
            <div className="mt-1 sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Notes</dt>
              <dd className="mt-1 text-sm text-gray-500">
                {booking.description}
              </dd>
            </div>
          )}

          {booking.stylist.fixed_location_address == null ||
          booking.stylist.fixed_location_coordinates == null ? (
            <div>At your location.</div>
          ) : (
            <div>
              At your service provider’s location:
              <br />{" "}
              <LocationLink
                fixedLocationAddress={booking.stylist.fixed_location_address}
                fixedLocationCoordinates={
                  booking.stylist.fixed_location_coordinates
                }
              />
            </div>
          )}

          <div className="mt-6 block items-center justify-between md:flex">
            {children}
          </div>
        </div>
      </div>
    </li>
  )
}

type AppointmentActionsProps = {
  booking: Booking
}

const AppointmentActions: React.FC<AppointmentActionsProps> = ({ booking }) => {
  function stateBadge() {
    switch (booking.state) {
      case "adjusted_original":
        return (
          <div className="mr-8 mb-3 flex items-center md:mb-0">
            <CheckBadgeIcon className="mr-1.5 h-8 w-8 flex-shrink-0 text-green-600" />
            <span className="font-bold text-green-600">Adjusted, original</span>
          </div>
        )

      case "canceled":
        return (
          <div className="mr-8 mb-3 flex items-center md:mb-0">
            <ExclamationCircleIcon className="mr-1.5 h-8 w-8 flex-shrink-0 text-yellow-600" />
            <span className="font-bold text-yellow-600">
              Canceled by service provider
            </span>
          </div>
        )

      case "completed":
        return (
          <div className="mr-8 mb-3 flex items-center md:mb-0">
            <CheckBadgeIcon className="mr-1.5 h-8 w-8 flex-shrink-0 text-green-600" />
            <span className="font-bold text-green-600">
              Waiting for payment
            </span>
          </div>
        )

      case "confirmed":
        return (
          <div className="mr-8 mb-3 flex items-center md:mb-0">
            <CheckBadgeIcon className="mr-1.5 h-8 w-8 flex-shrink-0 text-green-600" />
            <span className="font-bold text-green-600">Confirmed!</span>
          </div>
        )

      case "declined":
        return (
          <div className="mr-8 mb-3 flex items-center md:mb-0">
            <ExclamationCircleIcon className="mr-1.5 h-8 w-8 flex-shrink-0 text-yellow-600" />
            <span className="font-bold text-yellow-600">
              Declined by service provider
            </span>
          </div>
        )

      case "paid":
        return (
          <div className="mr-8 mb-3 flex items-center md:mb-0">
            <CheckBadgeIcon className="mr-1.5 h-8 w-8 flex-shrink-0 text-green-600" />
            <span className="font-bold text-green-600">Paid!</span>
          </div>
        )

      case "tentative":
        return (
          <div className="mr-8 mb-3 flex items-center md:mb-0">
            <ExclamationCircleIcon className="mr-1.5 h-8 w-8 flex-shrink-0 text-yellow-600" />
            <span className="font-bold text-yellow-600">
              Waiting for service provider
            </span>
          </div>
        )

      default:
        return (
          <i>
            Unknown appointment state <q>{booking.state}</q>
          </i>
        )
    }
  }

  return (
    <>
      {stateBadge()}
      <Link
        to={`/booking-detail/like/${booking.id}`}
        type="button"
        className="ml-auto inline-flex items-center rounded-md border border-transparent bg-gray-100 px-3 py-3 font-medium leading-4 text-indigo-700 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Book similar
      </Link>
      <Link
        to={`/messages/${booking.stylist.uuid}`}
        type="button"
        className="ml-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-3 font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Send message
      </Link>
    </>
  )
}

const BookingsCard = () => {
  const { data, isLoading, error } = useApi(
    ClientsService.listClientAppointments,
    {
      notStates: ["adjusted_gone", "canceled", "declined", "rescheduled_gone"],
    }
  )
  const {
    data: invitesData,
    isLoading: invitesLoading,
    error: invitesError,
  } = useGet("/api/clients/appt_invite_list/")

  let bookings: Booking[] = []

  if (!isLoading && !error && data !== undefined) {
    bookings = data
      .map((b): Booking => {
        const start = DateTime.fromISO(b.start)
        const end = DateTime.fromISO(b.end)
        const d: Booking = {
          id: b.id,
          cost: format.cents(b.total_cost_cents),
          duration: end.diff(start),
          name: b.stylist.name || "anonymous",
          description: b.description,
          services: b.services.map(s => ({
            name: s.name,
            cost: format.cents(s.cost_cents),
            duration: s.duration_minutes,
          })),
          start: start,
          state: b.state,
          stylist: {
            picture: b.stylist.avatar_url,
            uuid: b.stylist.uuid,
            id: b.stylist.id,
            userId: b.stylist.user_id,
            fixed_location_address: b.stylist.fixed_location_address,
            fixed_location_coordinates: b.stylist.fixed_location_coordinates,
          },
        }
        if (b.paid_amount_cents != null && b.paid_on != null) {
          d.cost = format.cents(b.paid_amount_cents)
          d.paid_on = DateTime.fromISO(b.paid_on)
        }
        return d
      })
      .concat(bookings)
  }

  const now = DateTime.now()
  const future: Booking[] = [],
    past: Booking[] = []
  for (const b of bookings) {
    const target = b.start! >= now ? future : past
    target.push(b)
  }

  future.sort((a, b) => a.start!.valueOf() - b.start!.valueOf())
  past.sort((a, b) => b.start!.valueOf() - a.start!.valueOf())

  const invites: Booking[] =
    invitesLoading || invitesError
      ? []
      : invitesData.map(
          (invite: any): Booking => ({
            id: invite.id,
            name: invite.stylist.name,
            cost: invite.cost,
            duration: Duration.fromObject({ minutes: invite.duration_minutes }),
            services: invite.services.map((s: any) => ({
              name: s.service.name,
              cost: format.dollars(s.cost),
              duration: s.duration,
            })),
            start: invite.start && DateTime.fromISO(invite.start),
            stylist: {
              picture: invite.stylist.picture,
              uuid: invite.stylist.uuid,
              fixed_location_address: invite.stylist.fixed_location_address,
              fixed_location_coordinates:
                invite.stylist.fixed_location_coordinates,
            },
          })
        )

  return (
    <>
      <div className="grid grid-cols-1 gap-4 lg:col-span-2">
        {invites.length > 0 && !invitesLoading && !invitesError && (
          <Card
            error={invitesError}
            loading={invitesLoading}
            title="Waiting invitations"
          >
            {invites.map(invite => (
              <Detail booking={invite} key={invite.id}>
                <div />
                <Link
                  to={`/accept/${invite.id}`}
                  type="button"
                  className="mr-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-3 font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Review
                </Link>
              </Detail>
            ))}
          </Card>
        )}
        <Card error={error} loading={isLoading} title="Upcoming appointments">
          {future.length > 0 &&
            future.map(booking => (
              <Detail booking={booking} key={booking.id}>
                <AppointmentActions booking={booking} />
              </Detail>
            ))}
          {future.length === 0 && (
            <p className="p-6 pt-0 font-medium text-gray-500">
              No future appointments to show yet.
            </p>
          )}
        </Card>
        <Card error={error} loading={isLoading} title="Past appointments">
          {past.length > 0 &&
            past.map(booking => (
              <Detail booking={booking} key={booking.id}>
                <AppointmentActions booking={booking} />
              </Detail>
            ))}
          {past.length === 0 && (
            <p className="p-6 pt-0 font-medium text-gray-500">
              No past appointments to show yet.
            </p>
          )}
        </Card>
        {!isLoading &&
          !error &&
          !data &&
          !invitesLoading &&
          !invitesError &&
          !invitesData && (
            <Section title="No appointments">
              <p className="p-6 pb-8 font-medium text-gray-500">
                You haven't booked any appointments yet.
              </p>
            </Section>
          )}
      </div>
    </>
  )
}

export default BookingsCard
